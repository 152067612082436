<template>
  <v-data-table
    outlined
    :items="regimens"
    :sort-by="['dosage', 'duration']"
    :headers="[
      {
        text: 'Age Bracket',
        value: 'age_bracket',
      },
      {
        text: 'Dosage',
        value: 'dosage',
      },
      {
        text: 'Duration',
        value: 'duration',
      },
      {
        text: 'Indications',
        value: 'indications',
        sortable: false,
      },
      {
        text: 'Disclaimer',
        value: 'disclaimer',
        sortable: false,
        align: 'end'
      },
      {
        value: 'edit',
        sortable: false,
        align: 'end'
      },
    ]"
    hide-default-footer
    disable-pagination
    fixed-header
    dense
  >
    <template #top>
      <v-app-bar
        dense
        elevation="0"
      >
        <v-row>
          <v-col class="text-subtitle-1">
            Regimens
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              color="primary lighten-1"
              rounded
              x-small
              @click="addRegimen()"
            >
              <v-icon
                left
                small
                class="ml-n1 mr-1"
              >
                mdi-plus
              </v-icon>
              Regimen
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
    </template>
    <template #group.header="{ group, headers }">
      <td
        :colspan="headers.length"
        class="text-overline"
      >
        {{ group }}
      </td>
    </template>
    <template #item.age_bracket="{ item }">
      <age-bracket-chip
        v-for="bracket in $age_brackets.filter(x => item.age_bracket.includes(x.name.toLowerCase())).map(x => x.name)"
        :key="bracket"
        :bracket="bracket"
      />
    </template>
    <template #item.pediatric="{ item }">
      <v-edit-dialog
        :return-value.sync="item.pediatric"
        save-text="Update"
        large
      >
        <span
          :key="item.pediatric"
        >
          <v-icon
            v-if="item.pediatric"
            small
          >
            fal fa-fw fa-check
          </v-icon>
          <v-icon
            v-else
            key="fal fa-fw fa-dash"
            color="grey lighten-1"
            small
          >
            fal fa-horizontal-rule
          </v-icon>
        </span>
        <template #input>
          <v-radio-group v-model="item.pediatric">
            <v-radio
              :value="false"
              label="Adult dosage and duration"
            />
            <v-radio
              :value="true"
              label="Pediatric dosage and duration"
            />
          </v-radio-group>
        </template>
      </v-edit-dialog>
    </template>
    <template #item.dosage="{ item }">
      <span class="text-caption">{{ item.dosage }}</span>
    </template>
    <template #item.duration="{ item }">
      <span class="text-caption">{{ item.duration }}</span>
    </template>
    <template #item.indications="{ item }">
      <v-chip
        v-for="indication in item.indications"
        :key="indication.id"
        :color="stringColor(indication.indication.en, 0.25)"
        class="mr-1 px-2"
        x-small
      >
        {{ indication.internal_name ? indication.internal_name : indication.indication.en }}
      </v-chip>
    </template>
    <template #item.disclaimer="{ item }">
      <v-icon
        v-if="item.disclaimer"
        x-small
      >
        mdi-asterisk
      </v-icon>
    </template>
    <template #item.edit="{ item }">
      <v-edit-dialog
        :return-value.sync="item"
        save-text="Update"
        xlarge
      >
        <v-btn
          icon
          small
        >
          <v-icon
            x-small
          >
            fal fa-edit
          </v-icon>
        </v-btn>
        <template #input>
          <v-col class="mt-4">
            <v-autocomplete
              v-model="item.age_bracket"
              :items="$age_brackets"
              item-value="name"
              item-text="name"
              label="Age Bracket"
              small-chips
              clearable
              multiple
              dense
            >
              <template #selection="{ item }">
                <age-bracket-chip
                  :key="item.name"
                  :bracket="item.name"
                  x-small
                >
                  {{ item.name }}
                </age-bracket-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-combobox
              v-model.trim="item.dosage"
              label="Dosage"
              :items="regimens.map(x => x.dosage).filter(x => x)"
              hide-selected
              dense
              maxlength="512"
              counter
            />
          </v-col>
          <v-col>
            <v-select
              v-model.trim="item.drug_dosage_id"
              label="Standard Dosage"
              :items="standardDosages"
              item-value="id"
              item-text="dosage"
              menu-props="offsetY"
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model.trim="item.duration"
              label="Duration"
              dense
              maxlength="256"
              counter
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="item.indications"
              :items="$store.getters.getIndications"
              label="Indications"
              :item-text="x => x.internal_name ? x.internal_name : x.indication.en"
              item-value="id"
              menu-props="auto"
              deletable-chips
              small-chips
              clearable
              return-object
              multiple
              dense
            >
              <template #selection="{ item }">
                <v-chip
                  :key="item.id"
                  :color="stringColor(item.indication.en, 0.25)"
                  class="mr-1 mb-1 px-2"
                  x-small
                >
                  {{ item.internal_name ? item.internal_name : item.indication.en }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.internal_name ? item.internal_name : item.indication.en }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.sources.join(', ') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model.trim="item.disclaimer"
              label="Disclaimer"
              rows="2"
              dense
            />
          </v-col>
          <v-col>
            <div class="text-caption mb-4">
              References
            </div>
            <add-reference :references.sync="item.references" />
          </v-col>
        </template>
      </v-edit-dialog>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    components: {
      AgeBracketChip: () => import("@/components/AgeBracketChip.vue"),
      AddReference: () => import('@/components/reference/AddReference.vue'),
    },
    props: {
      items: {
        type: Array,
        default: () => ([]),
        required: true,
      },
      standardDosages: {
        type: Array,
        default: () => ([]),
        required: true,
      },
    },
    data () {
      return {
        regimens: this.items,
      }
    },
    methods: {
      addRegimen () {
        this.regimens.push({
          id: `new-${this.regimens.length + 1}`,
          dosage: null,
          duration: null,
          indications: [],
          age_bracket: [],
        })
      },
    },
  }
</script>
